// Generated by Framer (d2515d1)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Hero } from "https://framerusercontent.com/modules/hKjtTuWGYB451ckw6eTN/bbq95ZQZ142lE2fXscHd/Hero.js";
const HeroFonts = getFonts(Hero);

const cycleOrder = ["iHu6UdvD8", "DDR8dzHhC"];

const serializationHash = "framer-Pv6QA"

const variantClassNames = {DDR8dzHhC: "framer-v-1e6ob4w", iHu6UdvD8: "framer-v-ij6jiz"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "iHu6UdvD8", "Variant 2": "DDR8dzHhC"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "iHu6UdvD8"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "iHu6UdvD8", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-ij6jiz", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"iHu6UdvD8"} ref={ref ?? ref1} style={{backdropFilter: "blur(4.3px)", backgroundColor: "rgba(255, 255, 255, 0.38)", borderBottomLeftRadius: "50%", borderBottomRightRadius: "50%", borderTopLeftRadius: "50%", borderTopRightRadius: "50%", boxShadow: "0px 4px 8.8px 0px rgba(0,0,0,0.25)", WebkitBackdropFilter: "blur(4.3px)", ...style}} {...addPropertyOverrides({DDR8dzHhC: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-15t8ljw"} data-framer-name={"Inner Circle"} layoutDependency={layoutDependency} layoutId={"JIzaKjM3m"} style={{backdropFilter: "blur(4.3px)", backgroundColor: "rgba(255, 255, 255, 0.37)", borderBottomLeftRadius: "50%", borderBottomRightRadius: "50%", borderTopLeftRadius: "50%", borderTopRightRadius: "50%", boxShadow: "0px 4px 8.8px 0px rgba(0,0,0,0.25)", WebkitBackdropFilter: "blur(4.3px)"}}><ComponentViewportProvider ><motion.div className={"framer-16fo4tn-container"} layoutDependency={layoutDependency} layoutId={"rSdNT4SVs-container"}><Hero color={"var(--token-c7b016ac-a10b-4622-acaf-8a1593f166fd, rgb(255, 255, 255))"} height={"100%"} iconSearch={"play"} iconSelection={"Home"} id={"rSdNT4SVs"} layoutId={"rSdNT4SVs"} mirrored={false} selectByList={false} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Pv6QA.framer-1r6nq5b, .framer-Pv6QA .framer-1r6nq5b { display: block; }", ".framer-Pv6QA.framer-ij6jiz { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 130px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 130px; will-change: var(--framer-will-change-override, transform); }", ".framer-Pv6QA .framer-15t8ljw { align-content: center; align-items: center; aspect-ratio: 1 / 1; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: var(--framer-aspect-ratio-supported, 100px); justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 100px; will-change: var(--framer-will-change-override, transform); }", ".framer-Pv6QA .framer-16fo4tn-container { flex: none; height: 50px; position: relative; width: 50px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Pv6QA.framer-ij6jiz, .framer-Pv6QA .framer-15t8ljw { gap: 0px; } .framer-Pv6QA.framer-ij6jiz > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-Pv6QA.framer-ij6jiz > :first-child, .framer-Pv6QA .framer-15t8ljw > :first-child { margin-left: 0px; } .framer-Pv6QA.framer-ij6jiz > :last-child, .framer-Pv6QA .framer-15t8ljw > :last-child { margin-right: 0px; } .framer-Pv6QA .framer-15t8ljw > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 130
 * @framerIntrinsicWidth 130
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"DDR8dzHhC":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramersjRej403c: React.ComponentType<Props> = withCSS(Component, css, "framer-Pv6QA") as typeof Component;
export default FramersjRej403c;

FramersjRej403c.displayName = "Play Button";

FramersjRej403c.defaultProps = {height: 130, width: 130};

addPropertyControls(FramersjRej403c, {variant: {options: ["iHu6UdvD8", "DDR8dzHhC"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramersjRej403c, [{explicitInter: true, fonts: []}, ...HeroFonts], {supportsExplicitInterCodegen: true})